<template>
  <TTView>
    <VRow>
      <VCol>
        <CompetenciesTable
          :companies="companies"
          :competencies="competencies"
          :headers="$options.V2_COMPETENCY_TABLE_HEADER"
          :loading="loading"
        >
          <template #topActions>
            <VCol
              class="d-flex pa-0"
              cols="9"
            >
              <TTTextField
                v-model="searchString"
                append-icon="fal fa-search"
                class="search tt-light-mono-100--text"
                placeholder="Поиск"
              />

              <VBtn
                :to="{
                  name : Names.R_COMPETENCY_MATRIX_COMPETENCY_CREATE
                }"
                class="ml-3"
                color="primary"
              >
                <VIcon left>
                  fal fa-plus
                </VIcon>

                Новая компетенция
              </VBtn>
            </VCol>
          </template>

          <template #rowActions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  :to="{
                    name : Names.R_COMPETENCY_MATRIX_COMPETENCY,
                    params : {
                      competencyId : item.id
                    }
                  }"
                  class="mx-1"
                  color="blue"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  :to="{
                    name : Names.R_COMPETENCY_MATRIX_COMPETENCY_EDIT,
                    params : {
                      competencyId : item.id
                    }
                  }"
                  class="mx-1"
                  color="orange"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  class="mx-1"
                  color="red"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="deleteCompetency(item)"
                >
                  <VIcon small>
                    fal fa-trash
                  </VIcon>
                </VBtn>
              </template>

              <span>Удаление</span>
            </VTooltip>

            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  :to="{
                    name : Names.R_COMPETENCY_MATRIX_COMPETENCY_SKILLS,
                    params : {
                      competencyId : item.id
                    }
                  }"
                  class="mx-1"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-graduation-cap
                  </VIcon>
                </VBtn>
              </template>

              <span>Навыки</span>
            </VTooltip>
          </template>
        </CompetenciesTable>
        <VDialog
          v-model="confirmDialog"
          max-width="500px"
          @click:outside="handleCancel"
        >
          <VCard>
            <VCardTitle class="headline">
              Удалить компетенцию
            </VCardTitle>

            <VCardText>
              Компетенция <span class="red--text">{{ competency.name }}</span> будет удалена! <br>
              Действительно хотите удалить компетенцию?
            </VCardText>

            <VCardActions>
              <VSpacer />

              <VBtn
                text
                @click="handleCancel"
              >
                Нет
              </VBtn>

              <VBtn
                color="red darken-1"
                text
                @click="handleDelete"
              >
                Да
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import {
  DEFAULT_COMPETENCY,
  V2_COMPETENCY_TABLE_HEADER,
} from '../../components/competency-matrix/competencies/common';
import CompetenciesTable from '../../components/competency-matrix/competencies/CompetenciesTable.vue';

export default {
  name: 'Competencies',

  components: {
    CompetenciesTable,
  },

  inject: ['Names'],

  V2_COMPETENCY_TABLE_HEADER,

  data() {
    return {
      companies: [],
      competencies: [],
      competency: { ...DEFAULT_COMPETENCY },
      confirmDialog: false,
      loading: false,
      searchString: '',
      lastSearchString: null,
    };
  },

  watch: {
    searchString() {
      if (this.lastSearchString !== this.searchString) {
        this.fetchCompetencies();
      }
    },
  },

  async created() {
    await this.fetchCompetencies();
  },

  methods: {
    async fetchCompetencies() {
      this.lastSearchString = this.searchString;
      this.loading = true;
      try {
        const { competencies } = await this.$di.api.CompetencyMatrix.CompetenciesSearch(
          { query: this.searchString },
        );
        this.competencies = competencies;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    resetCompetencyEdit() {
      this.confirmDialog = false;
      this.competency = { ...DEFAULT_COMPETENCY };
    },

    handleCancel() {
      this.resetCompetencyEdit();
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.competency;
        const data = { id };

        await this.$di.api.CompetencyMatrix.CompetenciesDelete(data);

        this.resetCompetencyEdit();
        await this.fetchCompetencies();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetchCompetencies();
      }
    },

    deleteCompetency(item) {
      this.confirmDialog = true;
      this.competency = { ...item };
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;

  ::v-deep .v-icon {
    color: map-get($tt-light-mono-100, 'base') !important;
    caret-color: map-get($tt-light-mono-100, 'base') !important;
  }
}
</style>
