var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VDataTable',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.competencies,"loading":_vm.loading,"items-per-page":_vm.pagination.limit,"page":_vm.pagination.page,"server-items-length":_vm.total,"footer-props":{
    itemsPerPageOptions : _vm.pagination.itemsPerPageOptions,
    showFirstLastPage : true
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('VToolbar',{attrs:{"flat":""}},[_c('VToolbarTitle',[_vm._v("Компетенции")]),_c('VSpacer'),_vm._t("topActions")],2)]},proxy:true},{key:"item.companyId",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.companyName(item))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_vm._t("rowActions",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }