var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('CompetenciesTable',{attrs:{"companies":_vm.companies,"competencies":_vm.competencies,"headers":_vm.$options.V2_COMPETENCY_TABLE_HEADER,"loading":_vm.loading},scopedSlots:_vm._u([{key:"topActions",fn:function(){return [_c('VCol',{staticClass:"d-flex pa-0",attrs:{"cols":"9"}},[_c('TTTextField',{staticClass:"search tt-light-mono-100--text",attrs:{"append-icon":"fal fa-search","placeholder":"Поиск"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('VBtn',{staticClass:"ml-3",attrs:{"to":{
                name : _vm.Names.R_COMPETENCY_MATRIX_COMPETENCY_CREATE
              },"color":"primary"}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Новая компетенция ")],1)],1)]},proxy:true},{key:"rowActions",fn:function(ref){
              var item = ref.item;
return [_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                  name : _vm.Names.R_COMPETENCY_MATRIX_COMPETENCY,
                  params : {
                    competencyId : item.id
                  }
                },"color":"blue","icon":"","small":""}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Просмотр")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                  name : _vm.Names.R_COMPETENCY_MATRIX_COMPETENCY_EDIT,
                  params : {
                    competencyId : item.id
                  }
                },"color":"orange","icon":"","small":""}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Редактирование")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"red","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCompetency(item)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удаление")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"to":{
                  name : _vm.Names.R_COMPETENCY_MATRIX_COMPETENCY_SKILLS,
                  params : {
                    competencyId : item.id
                  }
                },"icon":"","small":""}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-graduation-cap ")])],1)]}}],null,true)},[_c('span',[_vm._v("Навыки")])])]}}])}),_c('VDialog',{attrs:{"max-width":"500px"},on:{"click:outside":_vm.handleCancel},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}},[_c('VCard',[_c('VCardTitle',{staticClass:"headline"},[_vm._v(" Удалить компетенцию ")]),_c('VCardText',[_vm._v(" Компетенция "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.competency.name))]),_vm._v(" будет удалена! "),_c('br'),_vm._v(" Действительно хотите удалить компетенцию? ")]),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Нет ")]),_c('VBtn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.handleDelete}},[_vm._v(" Да ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }