<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="competencies"
    :loading="loading"
    :items-per-page.sync="pagination.limit"
    :page.sync="pagination.page"
    :server-items-length="total"
    :footer-props="{
      itemsPerPageOptions : pagination.itemsPerPageOptions,
      showFirstLastPage : true
    }"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Компетенции</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <template #item.companyId="{ item }">
      {{ companyName(item) }}
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
import { DEFAULT_COMPETENCY_TABLE_HEADER } from './common';

export default {
  name: 'CompetenciesTable',

  props: {
    companies: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    competencies: {
      type: Array,
      required: true,
    },

    headers: {
      type: Array,
      default: () => DEFAULT_COMPETENCY_TABLE_HEADER,
    },

    total: {
      type: [Number, String],
      default: 0,
    },
  },

  data() {
    return {
      pagination: {
        page: 1,
        limit: 10,
        itemsPerPageOptions: [10, 50, 100, 500, 1000],
      },
    };
  },

  watch: {
    'pagination.page': {
      handler(page) {
        this.$emit('on-change-page', page);
      },
    },
    'pagination.limit': {
      handler(limit) {
        this.$emit('on-change-limit', limit);
      },
    },
  },

  methods: {
    companyName(item) {
      const company = this.companies.find((c) => c.id === item.companyId);

      if (company) return company.name;

      return '';
    },
  },
};
</script>
